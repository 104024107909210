/** 企业列表 */
<template>
  <div class="enterprise-content">
    <!-- <Filters /> -->
    <section class="back-fff">
      <div class="content pb-140">
        <div class="list-title justify-between-center ptb-32">
          <span>共检测到{{ pageTotal }}个相关企业</span>
          <!-- <el-button type="text" class="pa-0" @click="monitorAllBtn">批量监控</el-button> -->
        </div>
        <ul v-if="pageTotal > 0" class="enterprise-list">
          <li class="list-header theme-back">
            <el-row type="flex" align="center">
              <!-- <el-col :span="1">
                <el-checkbox ref="checkAll" v-model="checkAll" :indeterminate="isIndeterminate"
                  @change="handleCheckAll">
                </el-checkbox>
              </el-col> -->
              <el-col :span="6">公司名称</el-col>
              <el-col :span="3">法定代表人</el-col>
              <el-col :span="3">注册资本</el-col>
              <el-col :span="3">成立日期</el-col>
              <el-col :span="5">注册地址</el-col>
              <el-col :span="3">操作</el-col>
            </el-row>
          </li>
          <li
            class="list-li mb-10 ptb-20 border-r4 bax-shadow"
            v-for="(item, index) in tableData"
            :key="index"
          >
            <el-row type="flex" align="center">
              <!-- <el-col :span="1">
                <el-checkbox ref="checkLi" @change="handleCheck($event,item.id)"> </el-checkbox>
              </el-col> -->
              <el-col :span="6" class="enterprise-name pl-20">
                <router-link
                  style="display: inline"
                  target="_blank"
                  :to="{
                    path: '/enterprise-details',
                    query: { qn: item.name, provinces: item.base },
                  }"
                >
                  {{ item.name }}
                </router-link>
              </el-col>
              <el-col :span="3">{{ item.legalPersonName }}</el-col>
              <el-col :span="3">{{ item.regCapital }}</el-col>
              <el-col :span="3">{{ item.estiblishTime }}</el-col>
              <el-col :span="5">{{ item.base }}</el-col>
              <el-col :span="3" class="enterprise-monitor">
                <el-button
                  class="monitor-btn"
                  :loading="item.monitor_load"
                  @click="monitorBtn(item.id, index)"
                >
                  {{
                    !$store.getters.userToken || !item.associate_company_status
                      ? "监控"
                      : "取消监控"
                  }}
                </el-button>
              </el-col>
            </el-row>
            <!-- 企业标签 -->
            <!-- <div class="enterprise-tag">
              <el-tag class="mr-8" v-for="item in 20" :key="item" color="#6AA2FF" effect="dark" size="small ">
                {{ item }}
              </el-tag>
            </div> -->
          </li>
        </ul>
        <el-empty
          v-else
          description="对不起，暂未查询到相关信息，请重新调整关键词，重新搜索"
        ></el-empty>
        <Page :total="pageTotal" @changePage="changePage" :isMaxTotal="true" />
      </div>
    </section>
  </div>
</template>
<script>
import Filters from "@/components/Filters.vue";
import Page from "@/components/Page.vue";
import { getEpList, setEpMonitor } from "@/api/enterprise";
export default {
  components: {
    Filters,
    Page,
  },
  data() {
    return {
      tableData: [],
      pageTotal: 0,
      checkAll: false, // 全选状态
      isIndeterminate: false, // 全选样式
      checkedCities: [], // 多选值
    };
  },
  mounted() {
    this.getEpList();
  },
  methods: {
    // 获取企业列表
    async getEpList(page = 1) {
      try {
        let { data, status } = await getEpList(
          this.$route.query.search_val,
          page
        );
        if (status == 200) {
          this.tableData = data.items;
          this.pageTotal = (data.total *= 1) || 0;
          // 给数组每一项添加新字段
          this.tableData.forEach((item) => {
            this.$set(item, "monitor_load", false);
          });
        }
      } catch (error) {
        return false;
      }
    },
    changePage(page) {
      this.getEpList(page);
    },
    // 全选
    handleCheckAll(val) {
      this.checkedCities = [];
      if (val) {
        this.tableData.forEach((i) => {
          this.checkedCities.push(i.id);
        });
      }
      this.isIndeterminate = false;
      // 修改单选框样式状态
      this.$refs.checkLi.forEach((i) => {
        i.$el.getElementsByTagName("input")[0].checked = val;
        if (val) {
          i.$el
            .getElementsByClassName("el-checkbox__input")[0]
            .classList.add("is-checked");
        } else {
          i.$el
            .getElementsByClassName("el-checkbox__input")[0]
            .classList.remove("is-checked");
        }
      });
    },
    // 单选
    handleCheck(e, val) {
      if (e) {
        this.checkedCities.push(val);
      } else {
        this.checkedCities.splice(this.checkedCities.indexOf(val), 1);
      }
      // 修改全选框样式状态
      this.isIndeterminate =
        this.checkedCities.length &&
        this.checkedCities.length < this.tableData.length
          ? true
          : false;
      this.checkAll =
        this.checkedCities.length >= this.tableData.length ? true : false;
    },
    // 单个监控
    async monitorBtn(id, ind) {
      if (!this.$store.getters.userToken)
        return this.$message.warning("请先登录");
      this.tableData[ind].monitor_load = true;
      try {
        let { data, status, msg } = await setEpMonitor({keywords:id,monitor_status:this.tableData[ind].associate_company_status?2:1});
        if (status == 200) {
          this.tableData[ind].associate_company_status =
            !this.tableData[ind].associate_company_status;
          this.$message.success(
            this.tableData[ind].associate_company_status
              ? "监控成功"
              : "取消监控成功"
          );
        } else {
          this.$message.error(
            this.tableData[ind].associate_company_status
              ? "取消监控失败"
              : "监控失败"
          );
        }
        this.tableData[ind].monitor_load = false;
      } catch (error) {
        this.tableData[ind].monitor_load = false;
      }
    },
    // 批量监控
    monitorAllBtn() {
      if (this.checkedCities.length) {
        console.log(this.checkedCities);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .enterprise-list {
  .el-row {
    width: 100%;
    .el-col {
      text-align: center;
      .el-checkbox__inner {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        &::after {
          height: 8px;
          left: 5px;
        }
      }
    }
  }
  .list-header {
    height: 50px;
    display: flex;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .list-li {
    // height: 64px;
    position: relative;
    .el-col {
      white-space: nowrap; /*强制单行显示*/
      text-overflow: ellipsis; /*超出部分省略号表示*/
      overflow: hidden; /*超出部分隐藏*/
      display: inline-block;
      line-height: 36px;
      &.enterprise-name {
        text-align: left;
      }
      &.enterprise-monitor {
        // line-height: 64px;
      }
      span.is-checked {
        .el-checkbox__inner {
          background-color: #409eff;
        }
      }
      .el-checkbox__inner {
        background-color: #dadee7;
      }
    }
    .enterprise-tag {
      position: absolute;
      left: 50px;
      bottom: 20px;
      .el-tag {
        line-height: 24px;
        border: none;
      }
    }
  }
}
</style>